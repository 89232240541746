<template>
  <div class="bg-white h-full flex justify-center">
    <div class="md:w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="activeStepIndex"
      />

      <div class="text-center py-8">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />
        <span class="text-2xl font-semibold mb-6 text-bb-text-default">Set up an Account</span>
        <p class="text-bb-text-secondary mb-6">Our AI will use the information below for targeting purposes</p>
      </div>

      <form
        class="w-390 mx-auto pb-20"
        @keydown.prevent.enter="submit"
      >
        <div class="space-y-6">
          <p class="text-sm font-medium">Which type of customers are you targeting?</p>
          <div class="flex gap-4 justify-between h-24">
            <div
              class="cursor-pointer pl-4 w-full h-full flex items-center rounded-md border border-bb-neutral-100 transition duration-200 ease-in-out hover:border-bb-brand-purple"
              :class="B2B ? 'bg-bb-brand-purple' : 'bg-white'"
              @click="toggleCheckBox('B2B')"
            >
              <div class="flex flex-row gap-4">
                <check-box
                  input-name="b2b"
                  input-id="b2b"
                  :value="B2B"
                  @input="toggleCheckBox('B2B')"
                />
                <div>
                  <B2B_icon :state="B2B ? 'selected' : ''" />
                  <p
                    class="text-xs"
                    :class="B2B ? 'text-white' : ''"
                  >
                    Business to Business
                  </p>
                </div>
              </div>
            </div>
            <div
              class="cursor-pointer pl-4 w-full h-full flex items-center rounded-md border border-bb-neutral-100 transition duration-200 ease-in-out hover:border-bb-brand-purple"
              :class="B2C ? 'bg-bb-brand-purple' : 'bg-white'"
              @click="toggleCheckBox('B2C')"
            >
              <div class="flex flex-row gap-4">
                <check-box
                  input-name="b2c"
                  input-id="b2c"
                  :value="B2C"
                  @input="toggleCheckBox('B2C')"
                />
                <div>
                  <B2C_icon :state="B2C ? 'selected' : ''" />
                  <p
                    class="text-xs"
                    :class="B2C ? 'text-white' : ''"
                  >
                    Business to Customers
                  </p>
                </div>
              </div>
            </div>
          </div>

          <text-area
            v-model="siteInfoForm.businessDescription"
            class="w-full"
            label="Add a brief description of your Business"
            sub-label="Use a short and focused description (eg: “Store that sells affordable furniture and home goods“)"
            input-type="text"
            input-name="name"
            input-id="name"
            placeholder="Business description"
            :rows="4"
            :max-length="inputLengths.businessDescription.max"
            :min-length="inputLengths.businessDescription.min"
            :error-message="businessDescriptionErrorMessage"
            tooltip
            tooltip-theme="lg"
            @focusout="$v.siteInfoForm.businessDescription.$touch"
          >
            <template #tooltip-content>
              <span class="font-medium mb-2">Example of business descriptions:</span>
              <div class="flex flex-col gap-2 mt-2">
                <p>
                  <span class="font-bold text-bb-green-accepted">Good: </span>“Store that sells affordable furniture and
                  home goods.”
                </p>
                <p>
                  <span class="font-bold text-bb-warning-200">Bad: </span>“IKEA is a global home furnishings retailer
                  known for its affordable and stylish furniture, as well as a wide range of home accessories and decor.
                  The company follows a self-assembly model, enabling cost-effective production and efficient customer
                  transportation. IKEA is renowned for its Scandinavian design influence and commitment to
                  sustainability.”
                </p>
                <p>
                  <span class="font-bold text-bb-error">Very Bad: </span>“Transform your living spaces with IKEA’s
                  innovative and affordable furniture solutions. Elevate your style, simplify your life – because at
                  IKEA, we believe in creating homes that reflect who you are.”"
                </p>
              </div>
            </template>
          </text-area>
          <text-area
            v-model="siteInfoForm.industry"
            :disabled="isLoading"
            class="w-full"
            disable-resize
            label="Industry"
            sub-label="The industry in which the account operates in."
            input-type="text"
            input-name="name"
            input-id="name"
            tooltip
            tooltip-theme="lg"
            placeholder="Examples: “IT services”, “Healthcare”, “Banking”, “Gaming” e.t.c."
            :rows="4"
            :max-length="inputLengths.industry.max"
            :min-length="inputLengths.industry.min"
            :error-message="industryErrorMessage"
            @focusout="$v.siteInfoForm.industry.$touch"
          >
            <template #tooltip-content>
              <span class="font-medium mb-2"
                >Please describe which industries your company operates in, add them as words separated by space and
                commas</span
              >
            </template>
          </text-area>
        </div>

        <div class="flex justify-between mt-10">
          <MergeButtonRound
            button-type="secondary"
            :disabled="isLoading"
            @click="$emit('previous')"
          >
            <template #left-icon>
              <IcChevronLeft />
            </template>
            Previous
          </MergeButtonRound>
          <MergeButtonRound
            type="submit"
            :disabled="isLoading || $v.siteInfoForm.$invalid"
            @click="submit"
          >
            <template #right-icon>
              <IcChevronRight />
            </template>
            Next
          </MergeButtonRound>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import TextArea from '@/components/input/brightbid/TextArea'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import CheckBox from '@/components/input/brightbid/CheckBox'
import B2B_icon from '@/components/icon/brightbid/b2b_icon'
import B2C_icon from '@/components/icon/brightbid/b2c_icon'
import { mapActions } from 'vuex'
import Toast from '@/components/shared/Toast'

export default {
  name: 'CreateSiteWebsite',
  components: {
    IcChevronLeft,
    IcChevronRight,
    TextArea,
    HorizontalStepper,
    CheckBox,
    B2B_icon,
    B2C_icon,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      steps: ['Org setup', 'Account setup', 'Connections'],
      activeStepIndex: 1,
      B2B: false,
      B2C: false,
      siteInfoForm: {
        target: [],
        businessDescription: null,
        industry: null,
        siteID: null,
      },
      inputLengths: {
        businessDescription: {
          min: 25,
          max: 150,
        },
        industry: {
          min: 5,
          max: 30,
        },
      },
      isLoading: false,
    }
  },
  computed: {
    businessDescriptionErrorMessage() {
      const lengths = this.inputLengths.businessDescription
      if (!this.$v.siteInfoForm.businessDescription.$dirty) return null
      if (!this.$v.siteInfoForm.businessDescription.required) return 'Description is required'
      if (!this.$v.siteInfoForm.businessDescription.minLength) return `Please use at least ${lengths.min} characters.`
      if (!this.$v.siteInfoForm.businessDescription.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
    industryErrorMessage() {
      const lengths = this.inputLengths.industry
      if (!this.$v.siteInfoForm.industry.$dirty) return ''
      if (!this.$v.siteInfoForm.industry.maxLength) return `Please use ${lengths.max} characters or less`
      if (!this.$v.siteInfoForm.industry.minLength) return `Please use at least ${lengths.min} characters`
      if (!this.$v.siteInfoForm.industry.required) return 'This field is required'
      return ''
    },
  },
  mounted() {
    // set the default value of description
    this.siteInfoForm.businessDescription = this.context.businessDescription
    // set the default value of industry
    this.siteInfoForm.industry = this.context.industry

    // set the default value of target
    this.siteInfoForm.target = this.context.target ?? []
    this[this.siteInfoForm.target[0]] = true

    // set the site ID
    this.siteInfoForm.siteID = this.context.siteID
  },
  validations() {
    const descriptionLengths = this.inputLengths.businessDescription
    const industryLengths = this.inputLengths.industry
    return {
      siteInfoForm: {
        businessDescription: {
          required,
          minLength: minLength(descriptionLengths.min),
          maxLength: maxLength(descriptionLengths.max),
        },
        industry: {
          required,
          minLength: minLength(industryLengths.min),
          maxLength: maxLength(industryLengths.max),
        },
      },
    }
  },

  methods: {
    ...mapActions({
      createStore: 'store/createStore',
    }),
    showToast(title, message, type) {
      this.$toast.error({
        component: Toast,
        props: {
          title: title,
          message: message,
          type: type,
        },
      })
    },
    async submit() {
      this.$v.siteInfoForm.$touch()
      if (this.$v.siteInfoForm.$invalid || !this.siteInfoForm.target.length || this.isLoading) return

      this.isLoading = true

      const payload = {
        name: this.context.siteName,
        description: this.siteInfoForm.businessDescription,
        industry: this.siteInfoForm.industry,
        url: this.context.siteURL,
        type: 'standard',
        target: this.siteInfoForm.target,
        organization_id: this.context.organizationID,
        country_code: this.context.siteCountry.value,
        language_code: this.context.siteLanguage.value,
      }

      let goToNextStep = false
      if (!this.siteInfoForm.siteID) {
        // The site has not been created yet
        try {
          const resp = await this.$http.post(`/common/site`, payload)
          this.siteInfoForm['siteID'] = resp.data.id
          goToNextStep = true
        } catch (error) {
          console.log(error)
          this.showToast('Error', 'Failed to create a site', 'error')
        }
      } else {
        // Update the site
        try {
          await this.$http.patch(
            `/common/site/${this.siteInfoForm.siteID}/business`,
            // only include certain fields to update
            {
              description: payload.description,
              industry: payload.industry,
              type: payload.type,
              target: payload.target,
              organization_id: payload.organization_id,
            },
          )
          goToNextStep = true
        } catch (error) {
          console.log(error)
          this.showToast('Error', 'Failed to update the site', 'error')
        }
      }

      this.isLoading = false

      if (goToNextStep) {
        this.$emit('next', { ...this.siteInfoForm })
      }
    },
    toggleCheckBox(type) {
      this[type] = !this[type]
      if (this[type]) {
        if (!this.siteInfoForm.target.includes(type)) this.siteInfoForm.target.push(type)
      } else {
        this.siteInfoForm.target = this.siteInfoForm.target.filter(item => item !== type)
      }
      console.log(this.siteInfoForm.target)
    },
  },
}
</script>
