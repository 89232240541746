<template>
  <div class="bg-white h-full flex justify-center">
    <div
      v-if="pageLoading"
      class="fixed inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120">
          <p>Generating suggestions...</p>
        </brightbid-loader>
      </div>
    </div>
    <div class="w-1/2">
      <horizontal-stepper
        :steps="steps"
        :active-step-index="activeStepIndex"
      />

      <div class="text-center py-8 w-500 mx-auto">
        <img
          src="/content/images/brightbid/colored-brightbid-logo-only.svg"
          alt="Logo"
          class="mx-auto"
        />
        <span class="text-2xl font-semibold mb-6 text-bb-text-default">Your Target Goal</span>
        <div class="pb-4">
          <p class="text-sm font-normal">
            The target values are used for reporting only and can be changed at any time. Input targets for this Account
            to see how your performance is pacing.
          </p>
        </div>
      </div>
      <form class="w-500 mx-auto pb-10">
        <div class="p-4 flex items-center justify-between">
          <div class="flex gap-x-1">
            <template>
              <ic-target-budget
                :size="24"
                class="pr-1"
              />
            </template>
            <div class="cursor-pointer bb-text-headers font-medium px-2 flex">Spend Target</div>
            <new-tooltip
              v-if="tooltip"
              direction="bottom-start"
              :theme="tooltipTheme"
              class="mt-0"
            >
              <template #content>
                {{ tooltips.spend }}
              </template>
            </new-tooltip>
          </div>
          <text-input
            v-model="targetForm.spend"
            class="w-fit bb-text-secondary-gray pNumLarge"
            input-type="text"
            input-name="targetGoalSpend"
            input-id="targetGoalSpend"
            placeholder="N/A"
            :error-message="$v.targetForm.spend.$error ? 'Please enter a numeric value' : ''"
            @focusout="$v.targetForm.spend.$touch"
          >
            <template #suffix>
              <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">{{ siteDetails?.currency }}</div>
            </template>
          </text-input>
        </div>
        <div class="p-4 flex items-center justify-between">
          <div class="flex">
            <select-dropdown
              :selection-value="initialTargetGoal"
              :options="targetGoals"
              @select-item="changeTargetGoal"
            >
              <template #icon>
                <ic-target-cpa
                  v-if="selectedTargetGoalType == 'cpa'"
                  :size="24"
                  class="pr-1 text-bb-brand-purple"
                />
                <ic-target-roi
                  v-if="selectedTargetGoalType == 'roas'"
                  :size="24"
                  class="pr-1 text-bb-brand-purple"
                />
              </template>
            </select-dropdown>
            <new-tooltip
              v-if="tooltip"
              direction="bottom-start"
              :theme="tooltipTheme"
            >
              <template #content>
                {{ tooltipText }}
              </template>
            </new-tooltip>
          </div>
          <div class="relative">
            <text-input
              v-if="selectedTargetGoalType == 'cpa'"
              v-model="targetForm.cpa"
              class="w-fit bb-text-secondary-gray pNumLarge"
              input-type="text"
              input-name="targetGoalCpa"
              input-id="targetGoalCpa"
              placeholder="N/A"
              :error-message="$v.targetForm.cpa.$error ? 'Please enter a numeric value' : ''"
              @focusout="$v.targetForm.cpa.$touch"
            >
              <template #suffix>
                <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">
                  {{ siteDetails?.currency }}
                </div>
              </template>
            </text-input>
            <text-input
              v-else
              v-model="targetForm.roas"
              class="w-fit bb-text-secondary-gray pNumLarge"
              input-type="text"
              input-name="targetGoalRoas"
              input-id="targetGoalRoas"
              placeholder="N/A"
              :error-message="$v.targetForm.roas.$error ? 'Please enter a numeric value' : ''"
              @focusout="$v.targetForm.roas.$touch"
            >
              <template #suffix>
                <div class="absolute right-0 top-0 z-10 h-10 p-2 text-bb-disabled-gray">%</div>
              </template>
            </text-input>
          </div>
        </div>

        <!--BUTTONS-->
        <div class="w-390 mx-auto">
          <div class="flex justify-between mt-10">
            <MergeButtonRound
              button-type="secondary"
              @click="$emit('previous')"
            >
              <template #left-icon>
                <IcChevronLeft />
              </template>
              Previous
            </MergeButtonRound>
            <MergeButtonRound
              :disabled="$v.targetForm.$invalid || isLoading"
              type="submit"
              @click="submit"
            >
              <template #right-icon>
                <IcChevronRight />
              </template>
              Next
            </MergeButtonRound>
          </div>
        </div>
      </form>
      <div class="flex justify-center">
        <button
          class="text-bb-brand-purple"
          @click="$emit('finish', context)"
        >
          Skip this step
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IcChevronLeft from 'vue-material-design-icons/ChevronLeft'
import IcChevronRight from 'vue-material-design-icons/ChevronRight'
import HorizontalStepper from '@/components/onboard-components/HorizontalStepper'
import IcTargetBudget from '@/components/icon/ic-target-budget.vue'
import IcTargetCpa from '@/components/icon/ic-target-cpa.vue'
import IcTargetRoi from '@/components/icon/ic-target-roi.vue'
import NewTooltip from '@/components/alert/NewTooltip'
import TextInput from '@/components/input/brightbid/TextInput'
import { decimal, minValue } from 'vuelidate/lib/validators'
import Toast from '@/components/shared/Toast'
import BrightbidLoader from '@/components/loader/BrightbidLoader'
import SelectDropdown from '@/components/input/brightbid/SelectDropdown'
import { mapActions, mapState } from 'vuex'

const TARGET_GOALS_OPTIONS = [
  { label: 'CPA Target', value: 'cpa' },
  { label: 'ROAS Target', value: 'roas' },
]
const TOOLTIPS = {
  spend:
    'Please note, this is used for reporting and monitoring only. Input what you wish to spend each month, this can be changed at any time from account settings.',
  cpa: 'Please note, this is used for reporting and monitoring only. Input what you wish to have in Cost Per Acquisition each month, this can be changed at any time from account settings.',
  roas: 'Please note, this is used for reporting and monitoring only. Input what you wish to have in Return On Ad Spend each month, this can be changed at any time from account settings.',
}

export default {
  name: 'SetupTargetGoals',
  components: {
    IcChevronLeft,
    IcChevronRight,
    HorizontalStepper,
    IcTargetBudget,
    IcTargetCpa,
    IcTargetRoi,
    NewTooltip,
    TextInput,
    BrightbidLoader,
    SelectDropdown,
  },
  provide() {
    return {
      context: this.context,
    }
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  validations() {
    return {
      targetForm: {
        spend: { decimal, minValue: minValue(0) },
        cpa: { decimal, minValue: minValue(0) },
        roas: { decimal, minValue: minValue(0) },
      },
    }
  },
  data() {
    return {
      steps: ['Org setup', 'Account setup', 'Connections'],
      activeStepIndex: 2,
      isLoading: false,
      targetGoals: TARGET_GOALS_OPTIONS,
      initialTargetGoal: TARGET_GOALS_OPTIONS[0],
      tooltips: TOOLTIPS,
      tooltipText: '',
      tooltipTheme: 'sm',
      tooltip: true,
      targetForm: {
        spend: null,
        cpa: null,
        roas: null,
      },
      targetGoalAmount: '',
      selectedTargetGoalType: '',
      suggestedTargetGoals: {
        spend: null,
        cpa: null,
        roas: null,
      },
      pageLoading: false,
      isDropdownVisible: false,
    }
  },
  async mounted() {
    this.pageLoading = true
    await this.fetchSiteDetails(this.context.siteID)
    await this.initializeForm()
    this.pageLoading = false
  },
  computed: {
    ...mapState('site', ['siteDetails']),
  },
  methods: {
    ...mapActions('site', ['fetchSiteDetails']),
    async initializeForm() {
      this.setSelectedTargetGoalType(this.initialTargetGoal.value)
      const suggestion = await this.fetchSuggestion()

      if (suggestion) {
        this.setTargetForm(this.suggestedTargetGoals)
      }
    },
    setSelectedTargetGoalType(value) {
      this.selectedTargetGoalType = value
      this.tooltipText = this.tooltips[value]
    },
    setTargetForm(suggestedTargetGoals) {
      this.targetForm.spend = suggestedTargetGoals.spend
      this.targetForm[this.selectedTargetGoalType] = suggestedTargetGoals[this.selectedTargetGoalType]
    },
    async fetchSuggestion() {
      try {
        const resp = await this.$http.get(`common/site/${this.context.siteID}/target-metric/suggest`)
        if (resp && resp.status === 200) {
          this.suggestedTargetGoals = resp.data
          return true
        }
      } catch (err) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Failed',
            message: `Sorry, we couldn't generate any suggestions. You may either provide more information or choose to skip this step.`,
            type: 'info',
          },
        })
      }
      return false
    },
    changeTargetGoal(item) {
      this.targetForm.cpa = null
      this.targetForm.roas = null
      this.setSelectedTargetGoalType(item.value)
      this.targetForm[this.selectedTargetGoalType] = this.suggestedTargetGoals[this.selectedTargetGoalType]
    },
    createPayload() {
      return Object.keys(this.targetForm).reduce((item, key) => {
        item[key] = parseFloat(this.targetForm[key])
        return item
      }, {})
    },
    async submit() {
      this.isLoading = true
      try {
        this.$v.targetForm.$touch()
        if (this.$v.targetForm.$invalid) {
          return
        }
        const payload = this.createPayload()
        const resp = await this.$http.patch(`common/site/${this.context.siteID}/metric-targets`, payload)

        if (resp && resp.status === 200) {
          this.$emit('finish', this.context)
        }
      } catch (err) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Failed',
            message: err ? err : 'Failed to save target goals.',
            type: 'warning',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
.error-message {
  font-size: 10px;
}
</style>
